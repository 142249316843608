import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import asset15 from '../../images/Asset-15.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={7}>
          <h2 className="text-blue">{t('Iso4001StandardTitle')}</h2>
          <div className="line blue-line" />
          <ul>
            <li>{t('Iso4001StandardLi1')}</li>
            <li>{t('Iso4001StandardLi2')}</li>
            <li>{t('Iso4001StandardLi3')}</li>
            <li>{t('Iso4001StandardLi4')}</li>
            <li>{t('Iso4001StandardLi5')}</li>
          </ul>
          <p>{t('Iso4001StandardParagraph')}</p>
          <ContactRequest />
        </Col>
        <Col lg={5} className="mt-4">
          <img
            src={asset15}
            alt={t('BuildingEnvironmentalSystemAlt')}
            className="img img-w-400 block m-auto"
          />
        </Col>
      </Row>
    </Container>
  );
};
