import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsEtusivuImage from '../../components/images/QMCloudsEtusivuImage';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage>
      <Container className="container-space text-white">
        <Row>
          <Col>
            <h2 className="text-white">{t('EnvironmentalSystemCoreTitle')}</h2>
            <div className="line white-line" />
            <p>{t('EnvironmentalSystemCoreParagraph1')}</p>
            <p>{t('EnvironmentalSystemCoreParagraph2')}</p>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <QMCloudsEtusivuImage className="img-rounded" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
