import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TextPiece from '../../components/TextPiece';
import asset11 from '../../images/Asset-11.svg';
import asset14 from '../../images/Asset-14.svg';
import asset16 from '../../images/Asset-16.svg';
import asset19 from '../../images/Asset-19.svg';
import asset20 from '../../images/Asset-20.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space spacing-20">
      <Row>
        <Col>
          <h2 className="text-blue text-center">
            {t('BenefitOfEnvironmentalSystemTitle')}
          </h2>
          <h3 className="text-center">
            {t('BenefitOfEnvironmentalSystemParagraph')}
          </h3>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={6} className="mt-4">
          <TextPiece
            hTag="h4"
            title={t('BenefitOfEnvironmentalSystemSubTitle1')}
            paragraphs={[t('BenefitOfEnvironmentalSystemParagraph2')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={asset14}
            alt={t('BenefitOfEnvironmentalSystemAlt1')}
            className="img img-w-250 block m-auto"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <img
            src={asset16}
            alt={t('BenefitOfEnvironmentalSystemAlt2')}
            className="img img-w-250 block m-auto"
          />
        </Col>
        <Col lg={6} className="mt-5">
          <TextPiece
            hTag="h4"
            title={t('BenefitOfEnvironmentalSystemSubTitle2')}
            paragraphs={[t('BenefitOfEnvironmentalSystemParagraph3')]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mt-5">
          <TextPiece
            hTag="h4"
            title={t('BenefitOfEnvironmentalSystemSubTitle3')}
            paragraphs={[t('BenefitOfEnvironmentalSystemParagraph4')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={asset11}
            alt={t('BenefitOfEnvironmentalSystemAlt3')}
            className="img img-w-250 block m-auto"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <img
            src={asset19}
            alt={t('BenefitOfEnvironmentalSystemAlt4')}
            className="img img-w-250 block m-auto"
          />
        </Col>
        <Col lg={6} className="mt-5">
          <TextPiece
            hTag="h4"
            title={t('BenefitOfEnvironmentalSystemSubTitle4')}
            paragraphs={[t('BenefitOfEnvironmentalSystemParagraph5')]}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} className="mt-3">
          <TextPiece
            hTag="h4"
            title={t('BenefitOfEnvironmentalSystemSubTitle5')}
            paragraphs={[t('BenefitOfEnvironmentalSystemParagraph6')]}
          />
        </Col>
        <Col lg={6}>
          <img
            src={asset20}
            alt={t('BenefitOfEnvironmentalSystemAlt5')}
            className="img img-w-250 block m-auto"
          />
        </Col>
      </Row>
    </Container>
  );
};
