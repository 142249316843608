import React from 'react';
import Layout from '../components/layout';
import QualityManualRequestWithBg from '../components/QualityManualRequestWithBg';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import BenefitOfEnvironmentalSystem from '../pages-partials/environmental-system/BenefitOfEnvironmentalSystem';
import BuildingEnvironmentalSystem from '../pages-partials/environmental-system/BuildingEnvironmentalSystem';
import EnvironmentalSystemCore from '../pages-partials/environmental-system/EnvironmentalSystemCore';
import EnvironmentalSystemHero from '../pages-partials/environmental-system/EnvironmentalSystemHero';
import Iso4001Standard from '../pages-partials/environmental-system/Iso4001Standard';
import WhatIsIso4001 from '../pages-partials/environmental-system/WhatIsIso4001';
import WhereToStartCreating from '../pages-partials/environmental-system/WhereToStartCreating';

const EnvironmentalSystemPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('EnvironmentalSystemSeoTitle')}
      description={t('EnvironmentalSystemSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/environmental-system"
      lang={locale}
    />
    <EnvironmentalSystemHero />
    <BenefitOfEnvironmentalSystem />
    <QualityManualRequestWithBg />
    <BuildingEnvironmentalSystem />
    <WhatIsIso4001 />
    <Iso4001Standard />
    <EnvironmentalSystemCore />
    <WhereToStartCreating />
  </Layout>
);

export default withI18next({ ns: 'common' })(EnvironmentalSystemPage);
