import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LinkTo from '../../components/LinkTo';
import TextPiece from '../../components/TextPiece';
import urls from '../../components/urls';
import asset17 from '../../images/Asset-17.svg';
import asset18 from '../../images/Asset-18.svg';

export default () => {
  const { t } = useTranslation();

  return (
    <Container className="container-space">
      <Row>
        <Col>
          <h2 className="text-blue">{t('WhereToStartCreatingTitle')}</h2>
          <div className="line blue-line" />
          <h3>{t('WhereToStartCreatingSubTitle')}</h3>
          <p>{t('WhereToStartCreatingParagraph1')}</p>
          <p>{t('WhereToStartCreatingParagraph2')}</p>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <img
            src={asset18}
            alt={t('WhereToStartCreatingAlt1')}
            className="img img-w-350 block m-auto"
          />
        </Col>
        <Col lg={6} className="mt-4">
          <TextPiece
            hTag="h3"
            title={t('WhereToStartCreatingSubTitle2')}
            paragraphs={[t('WhereToStartCreatingParagraph3')]}
          />
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col>
          <h4 className="text-blue text-center">
            {t('WhereToStartCreatingSubTitle3')}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <TextPiece
            hTag="h3"
            title={t('WhereToStartCreatingSubTitle4')}
            paragraphs={[
              t('WhereToStartCreatingParagraph4'),
              t('WhereToStartCreatingParagraph5'),
            ]}
          />
          <LinkTo url={urls.softwareUrl} text={t('ReadMoreLinkToSoftware')} />
        </Col>
        <Col lg={6}>
          <img
            src={asset17}
            alt={t('WhereToStartCreatingAlt2')}
            className="img img-w-350 block m-auto"
          />
        </Col>
      </Row>
    </Container>
  );
};
