import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import asset12 from '../../images/Asset-12.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row>
        <Col lg={8}>
          <h2 className="text-blue">
            {t('BuildingEnvironmentalSystemTitle1')}
          </h2>
          <div className="line blue-line" />
          <p>{t('BuildingEnvironmentalSystemPara1')}</p>
          <h2 className="text-blue">
            {t('BuildingEnvironmentalSystemTitle2')}
          </h2>
          <div className="line blue-line" />
          <p>{t('BuildingEnvironmentalSystemPara2')}</p>
        </Col>
        <Col lg={4} className="mt-4">
          <img
            src={asset12}
            alt={t('BuildingEnvironmentalSystemAlt')}
            className="img img-w-350 block m-auto"
          />
        </Col>
      </Row>
    </Container>
  );
};
