import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsTietokonePuhelinImg from '../../components/images/QMCloudsTietokonePuhelinImg';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="container-space text-white">
      <Container>
        <Row>
          <Col lg={6}>
            <QMCloudsTietokonePuhelinImg className="img-w-500" />
          </Col>
          <Col lg={6} className="mt-5">
            <h2 className="text-white">{t('WhatIs4001Title')}</h2>
            <p>{t('WhatIs4001Para1')}</p>
            <p>{t('WhatIs4001Para2')}</p>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
